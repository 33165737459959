.loginbtn {
  margin-left: auto;
  @include button;
  width: 8rem;
  border: none;
  &:hover {
    background-color: #d21034;
    color: rgb(255, 255, 255);
  }
  &:focus {
    outline: none;
  }
}
