.setting {
  padding: 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  border-radius: 4px;
  width: 46%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  &__profile_name {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__profile_illustration {
    font-size: 1.3rem;
    color: #989fa8;
  }
  &__profileSec {
    // width: 100%;
  }
  &__editSec {
    display: flex;
    align-items: center;
    width: 8%;
    justify-content: space-between;
  }
  &__edit-icon {
    width: 1.5rem;
  }
  &__edit-name {
    font-size: 1.2rem;
  }
}
