.dashboardDetails {
  width: 100%;
  // height: 55rem;
  // background-color: #fff;

  &__heading {
  }
  &__add_section {
    display: flex;
    justify-content: space-between;
  }
  &__addButton {
    @include button;
    background-color: #3a405a;
    color: #fff;
  }
  &__editing_mode {
    margin: 2rem 0;
    background: linear-gradient(90deg, transparent, transparent 1px, #f6f8f9 0, #f6f8f9),
      linear-gradient(180deg, #b3babf, #b3babf 1px, transparent 0, transparent);
    background-size: 5px 50px;
    background-position-y: -8px;
    position: relative;
    // height: 47rem;
    padding: 1rem;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0px;
      right: 0;
      background: linear-gradient(180deg, transparent, transparent 2px, #f6f8f9 0, #f6f8f9 5px),
        linear-gradient(270deg, #b3babf, #b3babf 1px, transparent 0, transparent);
      background-size: calc((100vw) / 6) 5px;
      background-position: -7px 1px;
    }
  }

  &__modal_selectOption {
    font-size: 1.2rem;
  }
  &__modal_selectOptionHeading {
    display: inline-block;
    max-width: 100%;
    margin: 1rem 0;
    font-size: 1.2rem;
  }

  &__button_wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 3rem;
  }
  &__button {
    @include button;
    background-color: #fff;
    margin-right: 1rem;
    &:hover {
      color: #3a405a;
      border-color: #3a405a;
    }

    &:last-child {
      background-color: #3a405a;
      color: #fff;
      margin: 0;
    }
  }
  &__table_sec {
    position: relative;
    margin-bottom: 2rem;
    // z-index: 10;
    // border: 1px solid gray;
    min-height: 50px; /* if you want Flexible textarea/div then give min-height instead of height */
    overflow: auto;
    // padding: 2px;
    resize: both;
    width: 100rem;
    // height: 100rem;
    font-size: 15px;
    color: #000;
    font: -webkit-small-control;
    background: #fff;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
   
  }
  &__table_header{
    display: flex;
    justify-content: space-between;
    background-color: #E1E5F2;
    padding: 1.5rem 1rem;
  }
  &__table_name{
    font-size: 1.4rem;
    font-weight: bold;

  }
  &__table_close{
    width: 1.6rem;
    cursor: pointer;
  }
  &__table{
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  }
}
