.table {
  


  &__status {
    border-radius: 2px;
    padding: 3px 6px 4px;
    font-weight: 500;
    font-size: 11px;
    background: rgba(102, 136, 153, 0.85);
    width: fit-content;
    color: #fff;
    margin-top: 0.5rem;
  }
}
