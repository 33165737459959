.newModal {
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    transform: scale(0);
    transition: transform 1s;
    &--shown {
      transform: scale(1);
    }
  }
  &__backdrop {
    height: 100%;
    width: 100%;
    background-color: rgba(#000, 0.5);
  }
  // Modal styles here
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex; // Override bootstrap's default styles
  flex-direction: column;
  z-index: 30; // Higher z-index than wrapper and backdrop to display this content on top of them
  // height: 70rem;
  width: 55rem;
  background-color: white;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: top left;
  transition: transform 0.5s 0.5s;
  &--shown {
    transform: translate(-50%, -50%) scale(1);
  }
  // Modal header
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 3rem;
    border-bottom: 1px solid #f0f0f0;
    background-color: #3a405a;
  }
  &__title {
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.2rem;
    letter-spacing: 0.3px;
  }
  &__closeIcon {
    height: 2rem;
    width: 2rem;
    &:hover {
      cursor: pointer;
    }
  }

  &__body {
    padding: 3.5rem 3rem 3rem;
  }
}
