.subHeader {
  background-color: #fff;
  padding: 1rem 0;
  margin-bottom: 3rem;
  display: flex;

  &__dropdown {
    width: fit-content;
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;
    position: relative;
    // z-index: 10;
    border-radius: 3px;
    display: flex;
    align-items: center;
    // background-color: whitesmoke;

    &:hover {
      cursor: pointer;
    }
  }

  &__dropdownContent {
    position: absolute;
    background-color: #fff;
    padding: 1rem 2rem;
    width: 30rem;
    top: 5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 10;
    // transition: transform 0.4s;
    transform-origin: top;
    // border: 1px solid #81858c;
  }

  &__dropdownContent--hidden {
    transform: scale(0);
  }

  // &__dropdownContent--shown {
  //   transform: scale(1);
  // }

  &__dropdownIndicator {
    height: 1.25rem;
    width: 1.25rem;
    margin-left: 1rem;
    transition: transform 0.4s;

    &:hover {
      cursor: pointer;
    }
  }

  &__dropdownIndicator--reversed {
    transform: rotate(-540deg);
  }

  &__dropdownOption {
    font-size: 1.5rem;
    text-decoration: none;
    padding: 0.8rem 1.5rem;
    // color: cornflowerblue;
    border: none;
    background-color: #fff;
  }

  &__dropdownOption:hover {
    background-color: #3a405a;
    color: #eff1f3;
  }
}
