.detailChip{
  display: flex;
  margin: 0 2.5rem;
  margin-top: 2rem;
  align-items: flex-end;
  &__icon{
    width: 1.5rem;
  }
  &__name{
    font-size: 1.3rem;
    margin-left: 1.5rem;
  }
}