.dropdownMenu {
  display: flex;
  margin-left: auto;
  cursor: pointer;
  padding: 2rem 0;
  &:hover {
    .dropdownMenu__list {
      display: block;
    }
  }

  &__picture {
    width: 3rem;
    margin-right: 1rem;
  }
  &__profile {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &_name {
      font-weight: bold;
    }
  }
  &__account {
    display: flex;
    justify-content: space-between;
  }
  &__arrow {
    width: 1rem;
  }
  &__list {
    display: none;
    position: absolute;
    bottom: 1rem;
    transform: translate(-53%, 100%);
    width: 25rem;
    padding: 1.5rem 0;
    background-color: #fff;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #ccd1d9;
    border-radius: 4px;
    margin-right: 10px;
    list-style: none;
    right: -13rem;
  }
  &__list-item {
    padding: 5px 20px;
    padding: 5px 2rem 1rem 2rem;
    cursor: pointer;
    font-size: 1.4rem;
    &:hover {
      font-weight: 700;
    }
  }
  &__underline {
    margin: 1rem 2rem;
    font-weight: lighter;
  }
}
