.autosuggest {
  display: flex;
  position: relative;
  margin-left: 7rem;

  &__btn {
    // position: relative;
    width: 4rem;
    // border: 1px solid #95afc0;
    border: none;
    background: transparent;
    background-color: #b2bec3;
    background-color: #aaa;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    &:hover {
      .autosuggest__search {
        filter: opacity(0.8);
      }
    }
  }

  &__search {
    // position: absolute;
    filter: opacity(0.6);
    width: 1.6rem;
  }
}
