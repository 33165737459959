@mixin button {
  line-height: 1.499;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 3.2rem;
  padding: 0 1.5rem;
  font-size: 1.3rem;
  border-radius: 2px;
  color: #595959;
  border-color: #e8e8e8;
  letter-spacing: .1px;
}
