.header {
  position: fixed;
  top: 0;
  left: 9rem;
  width: calc(100% - 9rem);
  height: 6.5rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  color: #5a616b;
  background-color: #fff;
  box-shadow: 0 7px 5px -5px rgba(0, 0, 0, 0.1);
  z-index: 30;

  &__brand-logo {
    width: 14rem;
  }

  &__notify-dropdown {
    display: flex;
    margin-left: auto;
  }

  &__notify {
    width: 2rem;
    margin-right: 2rem;
  }
}
