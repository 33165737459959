.footer {
  // position: relative;
  // top: 0;
  // left: 12rem;
  // padding: 3px 80px 5px 15px;
  // width: 100%;
  padding: 0px 0 0 7rem;
  // display: flex;
  &__branding_sec {
    background-color: #fff;
    padding: 3rem 10rem 3rem 4.5rem;
    // margin: 0 -90px 15px -60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__branding_icon {
    width: 17rem;
  }
  &__branding_social_icon {
    width: 2.7rem;
  }
  &__branding_social_icon {
    border-radius: 4px;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  &__branding_patent {
    font-size: 1.4rem;
  }
  &__company-sec {
    display: flex;
    width: 100%;
    padding: 2rem 2rem 0rem 5rem;
    // flex-direction: column;
    margin-bottom: 5rem;
  }
  &__company {
    display: flex;
    flex-direction: column;
    width: 43%;
    margin-right: 7rem;
  }
  &__company_info {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    font-weight: bolder;
  }
  &__company_content {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
  }
  &__company-links{
    margin-right: 19rem;
  }
  &__links {
    list-style-type: none;
    margin: 0;
    display: inline;
  }
  &__link {
    // &:first-child{
    //   margin-top: .7rem;
    // }
    margin-top: .4rem;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
