*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-weight: 400;

  border-radius: 1px;
}

:root {
  --homeHeaderHeight: 7rem;
  // --backgroundColor: #FCFFEB;
  // Colors:
  --bluePrimary: #002642;
  --whitePrimary: #e1e5ec;
  --yellowPrimary: #ecb111;
}

html {
  font-size: 62.5%;
}
body {
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  background-color: #f3f3f3;
}

.react-autosuggest__container {
  position: relative;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.react-autosuggest__input {
  width: 50rem;
  height: 32px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-appearance: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  overflow-y: scroll;
  height: auto;
  max-height: 20rem;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 30px;
  width: 35rem;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-sizing: border-box;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

// datatable override

.dataTables_wrapper {
  box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  // font-size: 1.4rem;
}
#DataTables_Table_0 {
  box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
}
#DataTables_Table_0_info{
  margin-left: 1rem;
}
#DataTables_Table_0_paginate{
  margin-right: 1rem;
}
