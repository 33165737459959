.sidebar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 90px;
  height: 100%;
  z-index: 10;
  color: #fff;
  background-color: #d21034;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  &__nav {
    padding: 0rem 0 5rem 0;
    display: flex;
    height: calc(100% - 65px);
    height: 100%;
    flex-direction: column;
    margin: 0;
    list-style: none;
  }

  &__list {
    height: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    &:nth-last-child(2) {
      margin-top: 10rem;
    }

    &-subsidebar {
      overflow-y: auto;
      overflow-x: hidden;
      position: fixed;
      top: 65px;
      padding-top: 2rem;
      left: 90px;
      width: 260px;
      height: calc(100% - 65px);
      color: #33383f;
      background-color: #f6f8fa;
      box-shadow: 0 2px 4px 0 transparent;
      cursor: auto;
      transform: translateX(-200%);
      box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.09);

      &_title {
        font-size: 1.2rem;
        font-weight: 400;
        color: #9e9e9e;
        margin-left: 2.5rem;
        text-align: start;
        &--last-child {
          margin-top: 5rem;
        }
      }
    }

    &:hover {
      background-color: #b10d2c;
      cursor: pointer;
      .sidebar__list-subsidebar {
        transform: translateX(0);
      }
    }
  }

  &__list-name {
    // color: #000
    font-size: 1.3rem;
    margin-top: 0.5rem;
    font-weight: 100;
  }
  &__list-icon {
    &:nth-last-child(1) {
      width: 4.2rem;
    }
    width: 1.7rem;
  }
}
