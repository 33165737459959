.dashboard {
  // margin-left: 25px;
  // margin-right: 25px;
  min-height: 80rem;
  // margin-bottom: 30px;
  // margin-top: 0;
  // border: 0;
  // width: 100%;
  background-color: transparent;
  margin: 9rem 3rem 0 12rem;

  &__setting {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  &__inputForm {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 4px 11px;
    color: #595959;
    font-size: 13px;
    line-height: 35px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    transition: all 0.3s;
    &:hover {
      border-color: #3a405a;
      border-right-width: 1px !important;
    }
    &:focus {
      border-color: #3a405a;
      border-right-width: 1px !important;
      outline: none !important;
    }
  }
  &__form {
    height: 100%;
    // padding: 3.5rem 3rem 0;
  }
  &__button_wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 3rem;
  }
  &__button {
    @include button;
    background-color: #fff;
    margin-right: 1rem;
    &:hover {
      color: #3a405a;
      border-color: #3a405a;
    }

    &:last-child {
      background-color: #3a405a;
      color: #fff;
      margin: 0;
    }
  }
}
